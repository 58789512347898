import React, { useState, useEffect, useContext } from "react";
import { Button, Card, CardContent, TextField, Typography, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from "@mui/material";
import axios from "axios";

import { GlobalContext } from "../App";

export default function CreateItem() {
  const { items, setItems, users, setItemsLoading } = useContext(GlobalContext);
  const [inputData, setInputData] = useState({ userId: "", name: "", price: "" });

  useEffect(() => {
    if (users.length && !inputData.userId) {
      setInputData((inputData) => {
        return { ...inputData, userId: users[0].id };
      });
    }
  }, [users, inputData.userId]);

  if (!users.length || !inputData.userId) {
    return <Typography>Loading ...</Typography>;
  }

  function createItems() {
    const N_price = Number(inputData.price);

    if (N_price > 0 && inputData.name) {
      setItemsLoading(true);
      axios({
        url: "/api/createItems",
        method: "post",
        baseURL: process.env.REACT_APP_API_BASE_URL,
        headers: { "X-FUNCTIONS-KEY": process.env.REACT_APP_API_BEARER_TOKEN },
        data: { name: inputData.name, paidUserId: inputData.userId, paidAmounts: N_price, isAdjusted: false },
      })
        .then((response) => {
          setInputData((inputData) => {
            return { ...inputData, name: "", price: "" };
          });

          setItems([response.data, ...items]);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setItemsLoading(false);
        });
    }
  }

  return (
    <Card>
      <CardContent>
        <FormControl>
          <FormLabel sx={{ typography: "body2" }}>誰が払いましたか？</FormLabel>
          <RadioGroup
            row
            value={inputData.userId}
            onChange={(e) =>
              setInputData((inputData) => {
                return { ...inputData, userId: e.target.value };
              })
            }
          >
            {users.map((user) => (
              <FormControlLabel key={user.id} value={user.id} control={<Radio />} label={user.displayName} />
            ))}
          </RadioGroup>
        </FormControl>

        <TextField
          fullWidth
          margin="dense"
          value={inputData.name}
          onChange={(e) =>
            setInputData((inputData) => {
              return { ...inputData, name: e.target.value };
            })
          }
          label="何に使いましたか？"
          variant="standard"
        />

        <TextField
          fullWidth
          required
          margin="dense"
          type="number"
          value={inputData.price}
          onChange={(e) =>
            setInputData((inputData) => {
              return { ...inputData, price: e.target.value };
            })
          }
          label="金額を入力してください。"
          variant="standard"
          inputProps={{ inputMode: "numeric" }}
        />

        <Typography textAlign="right" sx={{ mt: "0.4em" }}>
          <Button onClick={createItems}>Create item</Button>
        </Typography>
      </CardContent>
    </Card>
  );
}

import React, { useContext, useState } from "react";
import { Button } from "@mui/material";
import SyncIcon from "@mui/icons-material/Sync";
import { styled } from "@mui/material/styles";
import axios from "axios";

import { GlobalContext } from "../App";

export default function SyncButton() {
  const { setItems } = useContext(GlobalContext);
  const [isRotate, setIsRotate] = useState(false);

  const ClickedSyncIcon = styled(SyncIcon)({
    position: "fixed",
    right: "0.7em",
    bottom: "1.5em",

    "@keyframes rotate": {
      from: {
        transform: "rotate(0deg)",
      },
      to: {
        transform: "rotate(-360deg)",
      },
    },
  });

  function onClickReload() {
    setIsRotate(true);

    axios({
      url: "/api/readAllItems",
      method: "get",
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: { "X-FUNCTIONS-KEY": process.env.REACT_APP_API_BEARER_TOKEN },
    })
      .then((response) => {
        setItems(response.data);
      })
      .catch((error) => {
        console.error(error);
      });

    setTimeout(function () {
      setIsRotate(false);
    }, 500);
  }

  return (
    <>
      <Button onClick={onClickReload}>
        <ClickedSyncIcon fontSize="large" sx={{ animation: isRotate ? "rotate 0.5s linear 0s" : "" }} />
      </Button>
    </>
  );
}

import React, { useContext } from "react";
// import { Container } from "@mui/material";

import { GlobalContext } from "../App";
// import Header from "../components/header";
// import Footer from "../components/footer";
import Loading from "../components/loading";
import AjustedItems from "../components/ajustedItems";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";

export default function History() {
  const { usersLoading, itemsLoading } = useContext(GlobalContext);

  if (usersLoading || itemsLoading) {
    return <Loading />;
  }

  return (
    <>
      <Link to={"/"}>
        <Button>Home に戻る</Button>
      </Link>
      {/* <Header /> */}
      <main>
        <AjustedItems />
      </main>
      {/* <Footer /> */}
    </>
  );
}

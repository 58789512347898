import React, { useContext } from "react";
import { Divider } from "@mui/material";

import { GlobalContext } from "../App";
// import Header from "../components/header";
// import Footer from "../components/footer";
import Loading from "../components/loading";
import CreateItem from "../components/createItem";
import Adjustment from "../components/adjustment";
import YetAjustItems from "../components/yetAjustItems";
import SyncButton from "../components/syncButton";

export default function Index() {
  const { usersLoading, itemsLoading } = useContext(GlobalContext);

  if (usersLoading || itemsLoading) {
    return <Loading />;
  }

  return (
    <>
      {/* <Header /> */}
      <main>
        {/* <Container> */}
        <CreateItem />
        <Adjustment />
        <Divider />
        <YetAjustItems />
        {/* </Container> */}
        <SyncButton />
      </main>
      {/* <Footer /> */}
    </>
  );
}

import React, { useContext, useState } from "react";
import { Button, Dialog, DialogTitle, DialogActions, List, ListItem, Typography } from "@mui/material";
import axios from "axios";

import { GlobalContext } from "../App";
import ReadAllItems from "../modules/readAllItems";

export default function Adjustment() {
  const { items, setItems, users, setItemsLoading } = useContext(GlobalContext);
  const [openAdjustAllItemsDialog, setOpenAdjustAllItemsDialog] = useState(false);

  const yetAdjustAmount = items.map((item) => (!item.isAdjusted ? item.paidAmounts : 0)).reduce((prev, curr) => prev + curr, 0);

  const adjustUsers = [];
  users.map((user) =>
    adjustUsers.push({
      ...user,
      yetAdjustPaidAmount: items.map((item) => (!item.isAdjusted && item.paidUserId === user.id ? item.paidAmounts : 0)).reduce((prev, curr) => prev + curr, 0),
      adjustAmount:
        yetAdjustAmount / users.length - items.map((item) => (!item.isAdjusted && item.paidUserId === user.id ? item.paidAmounts : 0)).reduce((prev, curr) => prev + curr, 0),
    })
  );

  function adjustAllItems() {
    setOpenAdjustAllItemsDialog(false);
    setItemsLoading(true);

    axios({
      url: "/api/adjustAllItems",
      method: "patch",
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: { "X-FUNCTIONS-KEY": process.env.REACT_APP_API_BEARER_TOKEN },
    })
      .then(async (response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(async () => {
        const res = await ReadAllItems();
        if (res !== undefined) setItems(res);

        setItemsLoading(false);
      });
  }

  return (
    <>
      <List>
        <ListItem>
          <Typography variant="body1">精算</Typography>
          <Button onClick={() => setOpenAdjustAllItemsDialog(true)}>すべて精算済みにする</Button>
        </ListItem>

        <ListItem>
          {adjustUsers.map((user) =>
            user.adjustAmount >= 100 ? (
              <Typography variant="body1">
                {user.displayName}が ¥ {user.adjustAmount >= 100 ? Math.floor(user.adjustAmount / 100) * 100 : 0} 送金すると調整できます
              </Typography>
            ) : (
              <></>
            )
          )}
        </ListItem>

        <ListItem>
          <Typography variant="body2" color="#616161">
            立替金額 :{adjustUsers.map((user) => ` ${user.displayName} (¥ ${user.yetAdjustPaidAmount})`)}
          </Typography>
        </ListItem>
      </List>

      <Dialog open={openAdjustAllItemsDialog} onClose={() => setOpenAdjustAllItemsDialog(false)}>
        <DialogTitle>すべて精算済みにしますか？</DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenAdjustAllItemsDialog(false)}>戻る</Button>
          <Button onClick={adjustAllItems} autoFocus>
            はい、送金しました
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, List, ListItem, ListItemText, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";

import { GlobalContext } from "../App";
import FormatDate from "../modules/formatDate";

export default function YetAjustItems() {
  const { items, setItems, users, setItemsLoading } = useContext(GlobalContext);
  const [inputDeleteItems, setInputDeleteItems] = useState({});
  const [openDeleteItemsDialog, setDeleteItemsDialog] = useState(false);

  // function adjustItems(adjustItem) {
  //   setItemsLoading(true);

  //   axios({
  //     url: "/api/patchItems/" + adjustItem.id,
  //     method: "patch",
  //     baseURL: process.env.REACT_APP_API_BASE_URL,
  //     headers: { "X-FUNCTIONS-KEY": process.env.REACT_APP_API_BEARER_TOKEN },
  //     data: [{ op: "set", path: "/isAdjusted", value: !adjustItem.isAdjusted }],
  //   })
  //     .then((response) => {
  //       console.log(response);

  //       setItems((items) => items.map((item) => (item.id === adjustItem.id ? { ...item, isAdjusted: !adjustItem.isAdjusted } : item)));
  //     })
  //     .catch((error) => {
  //       console.error(error);
  //     })
  //     .finally(() => {
  //       setItemsLoading(false);
  //     });
  // }

  function confirmDeleteItems(item) {
    setDeleteItemsDialog(true);
    setInputDeleteItems(item);
  }

  function cancelDeleteItems() {
    setDeleteItemsDialog(false);
    setInputDeleteItems({});
  }

  function deleteItems() {
    setItemsLoading(true);

    axios({
      url: "/api/deleteItems/" + inputDeleteItems.id,
      method: "delete",
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: { "X-FUNCTIONS-KEY": process.env.REACT_APP_API_BEARER_TOKEN },
    })
      .then(() => {
        setItems(items.filter((item) => item.id !== inputDeleteItems.id));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setItemsLoading(false);
      });
  }

  return (
    <>
      <List>
        <ListItem>
          <ListItemText primary="未精算アイテム" />
          <Link to={"/history"}>
            <Button>精算済みアイテム一覧</Button>
          </Link>
        </ListItem>

        {items.map((item) =>
          item.isAdjusted ? (
            <></>
          ) : (
            <ListItem key={item.id}>
              <ListItemText primary={item.name} secondary={`${FormatDate(item.createdAt)} : ${users.find((user) => user.id === item.paidUserId).displayName}`} />

              <ListItemText primary={`¥ ${item.paidAmounts}`} sx={{ textAlign: "right", marginRight: "0.4em" }} />

              <Tooltip title="Delete">
                <IconButton onClick={() => confirmDeleteItems(item)} size="small">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </ListItem>
          )
        )}
      </List>

      <Dialog open={openDeleteItemsDialog} onClose={cancelDeleteItems}>
        <DialogTitle>本当に削除しますか？</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {inputDeleteItems.id
              ? `${inputDeleteItems.name} (${users.find((user) => user.id === inputDeleteItems.paidUserId).displayName}) : ¥${inputDeleteItems.paidAmounts}`
              : ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDeleteItems}>戻る</Button>
          <Button onClick={deleteItems} autoFocus>
            削除する
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

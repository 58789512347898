import React, { createContext, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";

import Index from "./pages/index";
import History from "./pages/history";
import ReadAllItems from "./modules/readAllItems";
import ReadAllUsers from "./modules/readAllUsers";

export const GlobalContext = createContext();

export default function App() {
  const [items, setItems] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [itemsLoading, setItemsLoading] = useState(false);

  useEffect(() => {
    setItemsLoading(true);

    Promise.all([ReadAllItems(), ReadAllUsers()]).then(([resItems, resUsers]) => {
      if (resItems !== undefined) {
        setItems(resItems);
        setItemsLoading(false);
      }

      if (resUsers !== undefined) {
        setUsers(resUsers);
        setUsersLoading(false);
      }
    });
  }, []);

  return (
    <GlobalContext.Provider value={{ items, setItems, users, setUsers, usersLoading, setUsersLoading, itemsLoading, setItemsLoading }}>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="history" element={<History />} />
      </Routes>
    </GlobalContext.Provider>
  );
}

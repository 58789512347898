import axios from "axios";

export default async function ReadAllUsers() {
  try {
    const { data } = await axios({
      url: "/api/readAllUsers",
      method: "get",
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: { "X-FUNCTIONS-KEY": process.env.REACT_APP_API_BEARER_TOKEN },
    });

    return data;
  } catch (error) {
    console.error(error);

    return undefined;
  }
}

import React from "react";
import { CircularProgress, Grid } from "@mui/material";

export default function Loading() {
  return (
    <>
      <Grid container alignItems="center" justifyContent="center" direction="column" sx={{ minHeight: "100vh" }}>
        <Grid item xs={12}>
          <CircularProgress />
        </Grid>
      </Grid>
    </>
  );
}

import React, { useContext, useState } from "react";
import { Button, Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle, List, ListItem, ListItemText, Tooltip, IconButton } from "@mui/material";
import UndoIcon from "@mui/icons-material/Undo";
import axios from "axios";

import { GlobalContext } from "../App";
import FormatDate from "../modules/formatDate";

export default function YetAjustItems() {
  const { items, setItems, users, setItemsLoading } = useContext(GlobalContext);
  const [inputAdjustItems, setInputAdjustItems] = useState({});
  const [openAdjustItemsDialog, setAdjustItemsDialog] = useState(false);

  function confirmAdjustItems(item) {
    setAdjustItemsDialog(true);
    setInputAdjustItems(item);
  }

  function cancelAdjustItems() {
    setAdjustItemsDialog(false);
    setInputAdjustItems({});
  }

  function adjustItems() {
    setItemsLoading(true);
    axios({
      url: "/api/patchItems/" + inputAdjustItems.id,
      method: "patch",
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: { "X-FUNCTIONS-KEY": process.env.REACT_APP_API_BEARER_TOKEN },
      data: [{ op: "set", path: "/isAdjusted", value: !inputAdjustItems.isAdjusted }],
    })
      .then((response) => {
        // console.log(response);

        setItems((items) => items.map((item) => (item.id === inputAdjustItems.id ? { ...item, isAdjusted: !inputAdjustItems.isAdjusted } : item)));
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setItemsLoading(false);
      });
  }

  return (
    <>
      <List>
        <ListItem>
          <ListItemText primary="精算済アイテム 一覧" />
        </ListItem>

        {items.map((item) =>
          item.isAdjusted ? (
            <ListItem key={item.id}>
              <ListItemText primary={item.name} secondary={`${FormatDate(item.createdAt)} : ${users.find((user) => user.id === item.paidUserId).displayName}`} />

              <ListItemText primary={`¥ ${item.paidAmounts}`} sx={{ textAlign: "right", marginRight: "0.4em" }} />

              <Tooltip title="Delete">
                <IconButton onClick={() => confirmAdjustItems(item)} size="small">
                  <UndoIcon />
                </IconButton>
              </Tooltip>
            </ListItem>
          ) : (
            <></>
          )
        )}
      </List>

      <Dialog open={openAdjustItemsDialog} onClose={cancelAdjustItems}>
        <DialogTitle>{inputAdjustItems.isAdjusted ? "未精算に戻しますか？" : "精算済みにしますか？"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {inputAdjustItems.id
              ? `${inputAdjustItems.name} (${users.find((user) => user.id === inputAdjustItems.paidUserId).displayName}) : ¥${inputAdjustItems.paidAmounts}`
              : ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelAdjustItems}>戻る</Button>
          <Button onClick={adjustItems} autoFocus>
            {inputAdjustItems.isAdjusted ? "未精算に戻す" : "精算済みにする"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
